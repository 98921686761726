import React, { useEffect, useState } from 'react';
import { Container, RankColumn, RankItem } from './styles';
import { CircularProgress, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { getRankingBI } from '../../../services/bi';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import AnimateButton from 'ui-component/extended/AnimateButton';

const UserRankingFull = ({ open, handleClose }) => {
    const [loading, setLoading] = useState(true);
    const [ranking, setRanking] = useState([]);
    useEffect(() => {
        getRankingBI(true).then((resp) => {
            setRanking(resp.content.ranking);
            setLoading(false);
        });
    }, []);

    if (loading) {
        return null;
    }

    return (
        <Modal open={open} onClose={handleClose}>
            <Container>
                <h1>Ranking de usu�rios</h1>
                <RankColumn totalItems={ranking.length}>
                    {ranking.slice(0, 20).map((rank, key) => (
                        <Tooltip followCursor key={`ranking-${key}`} title={`${rank.access} acessos`} placement={'top'}>
                            <RankItem>
                                <strong>{key + 1}�</strong>
                                <span className={'image'}>
                                    <img src={rank.user_image} alt="" />
                                </span>
                                <span className={'user'}>{rank.user}</span>
                            </RankItem>
                        </Tooltip>
                    ))}
                </RankColumn>
                <RankColumn>
                    {ranking.slice(20, 40).map((rank, key) => (
                        <Tooltip followCursor key={`ranking-${key + 20}`} title={`${rank.access} acessos`} placement={'top'}>
                            <RankItem>
                                <strong>{key + 20 + 1}�</strong>
                                <span className={'image'}>
                                    <img src={rank.user_image} alt="" />
                                </span>
                                <span className={'user'}>{rank.user}</span>
                            </RankItem>
                        </Tooltip>
                    ))}
                </RankColumn>
                <RankColumn>
                    {ranking.slice(40, 60).map((rank, key) => (
                        <Tooltip followCursor key={`ranking-${key + 40}`} title={`${rank.access} acessos`} placement={'top'}>
                            <RankItem>
                                <strong>{key + 40 + 1}�</strong>
                                <span className={'image'}>
                                    <img src={rank.user_image} alt="" />
                                </span>
                                <span className={'user'}>{rank.user}</span>
                            </RankItem>
                        </Tooltip>
                    ))}
                </RankColumn>
                <RankColumn>
                    {ranking.slice(60, 80).map((rank, key) => (
                        <Tooltip followCursor key={`ranking-${key + 60}`} title={`${rank.access} acessos`} placement={'top'}>
                            <RankItem>
                                <strong>{key + 60 + 1}�</strong>
                                <span className={'image'}>
                                    <img src={rank.user_image} alt="" />
                                </span>
                                <span className={'user'}>{rank.user}</span>
                            </RankItem>
                        </Tooltip>
                    ))}
                </RankColumn>
                <RankColumn>
                    {ranking.slice(80, 100).map((rank, key) => (
                        <Tooltip followCursor key={`ranking-${key + 80}`} title={`${rank.access} acessos`} placement={'top'}>
                            <RankItem>
                                <strong>{key + 80 + 1}�</strong>
                                <span className={'image'}>
                                    <img src={rank.user_image} alt="" />
                                </span>
                                <span className={'user'}>{rank.user}</span>
                            </RankItem>
                        </Tooltip>
                    ))}
                </RankColumn>
                <Box sx={{ mt: 2, mr: 3 }}>
                    <AnimateButton>
                        <Button disableElevation size="medium" type="button" variant="contained" color="error" onClick={handleClose}>
                            Voltar
                        </Button>
                    </AnimateButton>
                </Box>
            </Container>
        </Modal>
    );
};
export default UserRankingFull;
